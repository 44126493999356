import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home"; // You will create this component
import About from "./pages/About"; // You will create this component
// import Experience from "./pages/Experience"; // You will create this component
import Contact from "./pages/Contact"; // You will create this component
import TermsAndConditions from "./pages/Terms";
import PrivacyPolicy from "./pages/Privacy";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          {/*   <Route path="/experience" element={<Experience />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
