import React from "react";
import Layout from "../components/Layout"; // Adjust the path as necessary
import portrait from "../assets/portrait.png";
import { FaLinkedin, FaGithub, FaInstagram, FaTwitter } from "react-icons/fa";
import logo from "../assets/Logo.png";

function Contact() {
  return (
    <>
      {" "}
      <nav className="fixed top-0 left-0 w-full backdrop-blur-md bg-opacity-50 z-50 shadow-sm">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center h-16">
          <a href="/">
            <img className="w-10" src={logo} />
          </a>
        </div>
      </nav>
      <Layout>
        <div className="flex justify-center items-center h-screen">
          <div className="grid grid-cols-2 gap-10 max-w-4xl">
            <div className="flex flex-col items-start justify-center">
              <div className="w-32 h-32 mb-4">
                <img src={portrait} />
              </div>
              <h1 className="text-4xl font-bold mb-7 text-darkblue font-jetbrains">
                Hi There!
              </h1>
              <p className="mb-2 text-deepPink">Let me hear your thoughts.</p>
              <p className="text-darkblue  mb-2">
                We can also just grab coffee
              </p>
              <p className="font-bold bg-deepPink text-white px-1">
                itayinbar.me@gmail.com
              </p>
              <div className="flex space-x-4 mt-4">
                <a
                  href="https://www.linkedin.com/in/itayinbardev/"
                  className="text-darkblue hover:text-deepPink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size="1.5em" />
                </a>
                <a
                  href="https://github.com/itayinbarr"
                  className="text-darkblue hover:text-deepPink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGithub size="1.5em" />
                </a>
                <a
                  href="https://twitter.com/ItayInbarr"
                  className="text-darkblue hover:text-deepPink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter size="1.5em" />
                </a>
                <a
                  href="https://www.instagram.com/inb.art?utm_source=ig_web_button_share_sheet&igshid=ZDNlZDc0MzIxNw=="
                  className="text-darkblue hover:text-deepPink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size="1.5em" />
                </a>
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <p className="text-10xl text-lightblue">“</p>
              <div className="text-11xl  font-jetbrains">
                Cells that <b>fire</b> together,{" "}
                <span className="font-bold bg-deepPink mx-auto text-white px-1">
                  wire
                </span>{" "}
                together.
              </div>
              <div className="text-10xl text-lightblue mt-10">”</div>
              <p className="text-darkblue text-right">
                <b>Donald O. Hebb</b> | "The Organization of Behavior", 1949
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Contact;
