import React from "react";
import Layout from "../components/Layout"; // Adjust the path as necessary
import logo from "../assets/Logo.png"; // Make sure the path matches your file structure

function Home() {
  return (
    <Layout>
      <div className="flex justify-center items-center h-screen">
        <div className="text-center">
          <img src={logo} className="mx-auto w-1/5 md:w-1/5" alt="logo" />
          <h1 className="mt-6 text-4xl font-bold text-darkblue font-jetbrains">
            Itay Inbar
          </h1>
          <p className="mt-4 text-xl text-darkblue">
            If you're reading this, it means we have at least one common
            interest
          </p>
          <div className="mt-4 flex justify-center gap-4">
            <a
              href="/about"
              className="font-jetbrains border border-deepPink text-white bg-deepPink uppercase px-4 py-2 rounded-sm transition-colors duration-300 hover:bg-white hover:text-deepPink"
            >
              Let's find which one
            </a>
            {/*   <a
              href="/experience"
              className="border border-deepPink text-deepPink bg-white uppercase px-4 py-2 rounded-sm transition-colors duration-300 hover:bg-deepPink hover:text-white"
            >
              Experience
            </a> */}
          </div>{" "}
        </div>
      </div>
    </Layout>
  );
}

export default Home;
