import React from "react";
import { Link } from "react-router-dom";
const Layout = ({ children }) => {
  return (
    <>
      <main className="">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 ">
          {children}
        </div>
      </main>
      <footer className="bg-transparent pb-8">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <hr className="border-t-[1px] border-deepPink  w-1/12 mx-auto" />
          <div className="flex mt-3 justify-center space-x-4 text-slategray">
            <Link to="/terms">Terms & Conditions</Link>
            <span>|</span>
            <Link to="/privacy">Privacy Policy</Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Layout;
